import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";

import withStores from "@framework/src/libs/withStores";
import withStyles from "@framework/src/libs/withStyles";

import {
	ZipcodeTextField,
	NameTextField,
	EmailTextField,
	PhoneTextField,
	PasswordTextField,
	BasicTextField,
	CreditCardTextField,
	CreditCardCodeTextField,
	CreditCardExpTextField,
	MoneyTextField,
	DateTextField,
} from "./index.js";

// import ZipcodeTextField from "./types/ZipcodeTextField";
// import NameTextField from "./types/NameTextField";
// import EmailTextField from "./types/EmailTextField";
// import PhoneTextField from "./types/PhoneTextField";
// import PasswordTextField from "./types/PasswordTextField";
// import BasicTextField from "./types/BasicTextField";
// import CreditCardTextField from "./types/CreditCardTextField";
// import CreditCardCodeTextField from "./types/CreditCardCodeTextField";
// import CreditCardExpTextField from "./types/CreditCardExpTextField";
// import MoneyTextField from "./types/MoneyTextField";
// import DateTextField from "./types/DateTextField";

import { stores, styles } from "./.config";

import MaterialTextField from "@material-ui/core/TextField";

const TextField = compose(
	withStores(stores),
	withStyles(styles)
)(({ type, ...props }) => {
	return (
		<React.Fragment>
			{type === "zipcode" && <ZipcodeTextField type={type} {...props} />}
			{type === "name" && <NameTextField type={type} {...props} />}
			{type === "email" && <EmailTextField type={type} {...props} />}
			{type === "tel" && <PhoneTextField type={type} {...props} />}
			{type === "password" && <PasswordTextField type={type} {...props} />}
			{type === "text" && <BasicTextField type={type} {...props} />}
			{type === "number" && <BasicTextField type={type} {...props} />}
			{type === "cc" && <CreditCardTextField type={type} {...props} />}
			{type === "cc-code" && <CreditCardCodeTextField type={type} {...props} />}
			{type === "cc-exp" && <CreditCardExpTextField type={type} {...props} />}
			{type === "money" && <MoneyTextField type={type} {...props} />}
			{type === "date" && <DateTextField type={type} {...props} />}
		</React.Fragment>
	);
});

TextField.defaultProps = {
	type: "text",
};

TextField.propTypes = {
	type: PropTypes.oneOf([
		"email",
		"password",
		"name", //
		"zipcode",
		"tel",
		"text",
		"number",
		"cc",
		"cc-code",
		"cc-exp",
		"money",
		"date",
	]),
	validation: PropTypes.func,
	onBlur: PropTypes.func,
	errorHelperText: PropTypes.string,
	helperText: PropTypes.string,
	useDefaultEndAdornment: PropTypes.bool,
	useDefaultStartAdornment: PropTypes.bool,
	onValidation: PropTypes.func,
	disableErrorText: PropTypes.bool,
	disableOnBlurValidation: PropTypes.bool,
	required: PropTypes.bool,
	onChange: PropTypes.func,
	creditCardNumberId: PropTypes.string,
	creditCardNumber: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	showPassword: PropTypes.bool,
	name: PropTypes.string,
	variant: PropTypes.oneOf(["standard", "filled", "outlined"]),
	inputProps: PropTypes.object,
	keepCharPositions: PropTypes.bool,
	delimiter: PropTypes.string,
	placeholderChar: PropTypes.string,
	guide: PropTypes.bool,
	showMask: PropTypes.bool,
	InputProps: PropTypes.object,
	style: PropTypes.object,
	groupId: PropTypes.string,
	placeholder: PropTypes.string,
	...MaterialTextField.propTypes,
};

export default TextField;
